import React from 'react';
import Dialog from "../../Dialog";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as accountStore from "../../../store/Account";
import {AccountState, actionCreators as accountActions} from "../../../store/Account";
import {InputField, SubmitButton} from "../FormGroup";
import {Translate} from "react-localize-redux";
import {AppState} from "../../../store/configureStore";
import {OrganizationState} from "../../../store/OrganizationStore";
import {ErrorState} from "../../../store/Error";

interface Props{
    account: AccountState;
    accountErrors: ErrorState['account'];
    orgState: OrganizationState;
    accountActions: typeof accountActions;
}

const CreateAccountForm = (props: Props) => {
    const orgId = props.orgState.activeOrganization;
    const email = props.account.newAccount.email;
    return(
        <Dialog
            className='create-account-form-dialog'
            onClose={() => {props.accountActions.showCreateAccount(false)}}
            loading={props.account.isLoading.create}
        >
            <form onSubmit={e => e.preventDefault()}>
                <InputField
                    name='email'
                    type='email'
                    value={email}
                    onChange={e => props.accountActions.setCreateEmail(e.target.value)}
                    errors={props.accountErrors.create}
                    errorField='email'
                />
                {
                    props.accountErrors.create &&
                    ( props.accountErrors.create.status === 401 ?
                        <div className='form-error'>{props.accountErrors.create.status} <Translate id='unauthorized_error'/></div> :
                        ( props.accountErrors.create.status !== 400 &&
                        <div className='form-error'>{props.accountErrors.create.status} <Translate id='unknown_error'/></div> ))
                }
                <Translate id='use_myfloor_for_this' />
                <SubmitButton text='submit' onClick={() => props.accountActions.create(email, orgId)} split disabled/>
            </form>
        </Dialog>
    )
};

export default connect(
    (state: AppState) => ({
        account: state.account,
        accountErrors: state.errors[accountStore.reducerName],
        orgState: state.organization
    }),
    dispatch => ({
        accountActions: bindActionCreators(accountActions, dispatch)
    })
)(CreateAccountForm);