import React, {Component} from 'react';
import {Translate} from "react-localize-redux";
import {connect} from "react-redux";
import {AccountState, actionCreators as accountActions, TempAccount} from "../../store/Account";
import {actionCreators as meActions, MeState} from "../../store/Me";
import * as PlayListStore from '../../store/PlayListStore';
import {bindActionCreators} from "redux";
import {Loading} from "../Loading";
import * as GameStore from '../../store/games/Games';
import * as LibraryStore from '../../store/LibraryStore';
import {GameList} from '../../store/LibraryStore';
import * as OrganizationStore from '../../store/OrganizationStore';
import Game from "../ModelPreview/Game";
import ComponentSlider from "../Discover/ComponentSlider";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PlayListPreview from "../ModelPreview/PlayListPreview";
import Author from "../ModelPreview/Author";
import {history} from "../../index";
import {AppState} from "../../store/configureStore";
import {ErrorState} from "../../store/Error";
import {PlayListType} from "../../model/PlayListType";
import {AccountType, getFullName, isTempAccount} from "../../model/AccountType";
import {OrganizationName} from "./OrganizationName";
import {RouteComponentProps} from 'react-router-dom';
import ItemContainer from '../ModelPreview/ItemContainer';

interface Props extends RouteComponentProps<{id: string}>{
    accountActions: typeof accountActions;
    libraryActions: typeof LibraryStore.actionCreators;
    playListActions: typeof PlayListStore.actionCreators;
    orgActions: typeof OrganizationStore.actionCreators;
    meActions: typeof meActions;
    library: LibraryStore.LibraryState;
    accountState: AccountState;
    organizationState: OrganizationStore.OrganizationState;
    meState: MeState;
    playlist: PlayListStore.PlayListState;
    errorState: ErrorState;
}

class PublicProfile extends Component<Props> {

    constructor(props: Props){
        super(props);
        const idFromRouting = this.props.match.params.id;

        if (idFromRouting){
            this.refreshAccount(idFromRouting);
        }
    }

    componentDidUpdate(prevProps: Readonly<Props>): void {
        const idFromRouting = this.props.match.params.id;
        const prevId = prevProps.match.params.id;
        if(idFromRouting !== prevId) this.refreshAccount(idFromRouting);
    }

    refreshAccount = (id: string) => {
        this.props.accountActions.get(id, true);
        this.props.libraryActions.ppReset();
        this.props.libraryActions.ppSetId(id);
        this.props.playListActions.getPlayLists();
        this.props.accountActions.getFollowers(id);
    };

    render(){
        const props = this.props;
        const pp = props.library.publicProfile;
        const account = props.accountState.accounts[pp.id];
        const menuShowing = pp.menuShowing;
        if(!account) return null;
        if(!props.meState.account) return null;
        const followers = this.props.accountState.followers[pp.id];

        const dashBoard = (playLists: PlayListType[], gameList?: GameList) => {
            if(isTempAccount(account)) return false;
            return(
                <div className='pp-dashboard'>
                    <h2><Translate id='pp_dash_games' data={{name: account.firstname || ''}}/></h2>
                    <div className='relative'>
                        <Loading visible={props.library.loading.getGamesByAccount} />
                        <ComponentSlider width={220} height={160}>
                            {gameList ? gameList.games && gameList.games.map(game => {
                                const unitCount = gameList.playlistCounts?.find(x => x.id === game.id);
                                return <Game
                                    autoScale
                                    key={game.id}
                                    game={game}
                                    count={unitCount ? unitCount.count : undefined}
                                    onlyImage
                                />
                            }) : []}
                        </ComponentSlider >
                    </div>

                    <h2><Translate id='pp_dash_playlists' data={{name: account.firstname || ''}}/></h2>
                    <div className='relative'>
                        <Loading visible={props.library.loading.getPlayListsByAccount} />
                        <ComponentSlider width={220} height={160}>
                            {playLists && playLists.map(pl => {
                                return <PlayListPreview key={pl.id} playlist={pl} onlyImage/>
                            })}
                        </ComponentSlider>
                    </div>

                    <h2><Translate id='pp_dash_followings' data={{name: account.firstname || ''}}/></h2>
                    <ComponentSlider width={140} height={160}>
                        {account && account.followings && account.followings.map(x => {
                            return <Author
                                key={x}
                                accountId={x}
                                onClick={() => {
                                    history.push(`/library/profile/${x}`);
                                    props.libraryActions.ppSetId(x);
                                }}
                                fullImage
                            />
                        })}
                    </ComponentSlider>
                    <div className='clear-fix'/>

                </div>
            );
        };

        const gameList = (gameList?: GameList) => {
            return (
                <ItemContainer
                    heading={<h2><Translate id='pp_dash_games' data={{name: getFullName(account)}} /></h2>}
                    items={gameList?.games || []}
                    loading={props.library.loading.getGamesByAccount}
                    itemRender={game => {
                        const unitCount = gameList?.playlistCounts?.find(x => x.id === game.id);
                        return <Game key={game.id} game={game} count={unitCount ? unitCount.count : undefined} />
                    }}
                />
            )
        };

        const playListsList = (playLists: PlayListType[]) => {
            return(
                <ItemContainer
                    heading={<h2><Translate id='pp_dash_playlists' data={{name: getFullName(account)}} /></h2>}
                    items={playLists || []}
                    loading={playLists.length < 1 && props.library.loading.getPlayListsByAccount}
                    itemRender={pl => <PlayListPreview key={pl.id} playlist={pl} />}
                />
            )
        };

        const socialList = (account: AccountType | TempAccount) => {
            if(isTempAccount(account)) return false;
            return(
                <div className='social-list'>
                    <h1><Translate id='pp_following'/></h1>
                    <h3><Translate id='pp_followings_count' data={{count: account.followings.length || 0}} /></h3>
                    <div className='following-list'>
                        {account.followings.map(x => <Author key={x} accountId={x} onClick={() => {history.push(`/library/profile/${x}`);props.libraryActions.ppSetId(x);}}/>)}
                        <div className='clear-fix'/>
                    </div>
                    <h1><Translate id='pp_followers'/></h1>
                    <h3><Translate id={"pp_follower_count"} data={{count: followers?.resultCount || 0}} /></h3>
                    {followers?.accounts?.map(x => <Author key={x.id} account={x} onClick={() => {history.push(`/library/profile/${x.id}`);props.libraryActions.ppSetId(x.id);}}/>)}
                </div>
            )
        };

        const following = props.meState.account && props.meState.account.followings.includes(account.id);
        const followFunction = () => {
            if(following) props.meActions.unfollowAuthor(account.id);
            else props.meActions.followAuthor(account.id);
        };
        const followLoad = props.meState.isLoading.followAuthor || props.meState.isLoading.unfollowAuthor;

        return(
            <div className='public-profile'>
                <div className='pp-header'>
                    <Loading visible={isTempAccount(account)}/>
                    <div className='account-img'>
                        {! isTempAccount(account) && <Author account={account} fullImage hideName /> }
                    </div>
                    <div className='account-text'>
                        <div className='account-title'>
                            <div className='pp-name'>{!isTempAccount(account) && `${account.firstname} ${account.lastname}`}</div>
                                {
                                    !isTempAccount(account) &&
                                    <OrganizationName
                                        orgActions={props.orgActions}
                                        orgId={account.organizationId}
                                        miniOrg={props.organizationState.minimalOrgs[account.organizationId]}
                                    />
                                }
                            </div>
                        <div className='pp-description'>
                            {!isTempAccount(account) && account.description}
                        </div>
                    </div>
                    <div className='clear-fix' />
                </div>
                <div className='pp-menu'>
                    <div
                        className={`btn btn-black ${menuShowing === 'dashboard' ? 'active' : ''}`}
                        onClick={() => props.libraryActions.ppShowMenu('dashboard')}
                    >
                        <Translate id='pp_dashboard' />
                    </div>
                    <div
                        className={`btn btn-black ${menuShowing === 'games' ? 'active' : ''}`}
                        onClick={() => props.libraryActions.ppShowMenu('games')}
                    >
                        <Translate id='pp_games' />
                        <div className='count-text'>({pp.games ? pp.games.resultCount : ''})</div>
                    </div>
                    <div
                        className={`btn btn-black ${menuShowing === 'playLists' ? 'active' : ''}`}
                        onClick={() => props.libraryActions.ppShowMenu('playLists')}
                    >
                        <Translate id='pp_playlists' />
                        <div className='count-text'>({pp.playLists.length})</div>
                    </div>
                    <div
                        className={`btn btn-black ${menuShowing === 'social' ? 'active' : ''}`}
                        onClick={() => props.libraryActions.ppShowMenu('social')}
                    >
                        <Translate id='pp_social' />
                    </div>
                </div>
                { menuShowing === "dashboard" && dashBoard(pp.playLists, pp.games)}
                { menuShowing === "games" && gameList(pp.games) }
                { menuShowing === "playLists" && playListsList(pp.playLists)}
                { menuShowing === "social" && socialList(account)}
            </div>
        )
    }
}

export default connect(
    (state: AppState) => ({
        accountState: state.account,
        library: state[LibraryStore.reducerName],
        memoryState: state[GameStore.reducerName],
        playlist: state[PlayListStore.reducerName],
        meState: state.me,
        organizationState: state[OrganizationStore.reducerName],
        errorState: state.errors
    }),
    dispatch => ({
        accountActions: bindActionCreators(accountActions, dispatch),
        libraryActions: bindActionCreators(LibraryStore.actionCreators, dispatch),
        memoryActions: bindActionCreators(GameStore.actionCreators, dispatch),
        playListActions: bindActionCreators(PlayListStore.actionCreators, dispatch),
        meActions: bindActionCreators(meActions, dispatch),
        orgActions: bindActionCreators(OrganizationStore.actionCreators, dispatch)
    })
)(PublicProfile)