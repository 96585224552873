import React, {Component, MouseEvent} from 'react'
import {actionCreators} from "../../../store/Me";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {AppState, ChangeEvent} from "../../../store/configureStore";
import {InputField, SubmitButton} from "../FormGroup";
import {ErrorState} from "../../../store/Error";
import { Translate } from 'react-localize-redux';

interface Props{
    errors: ErrorState['me'];
    authActions: typeof actionCreators;
}

interface State{
    oldPassword: string;
    password: string;
    passwordRepeat: string;
}

class ChangePasswordForm extends Component<Props, State>{
    
    constructor(props: Props){
        super(props);

        this.state = {
            oldPassword: '',
            password: '',
            passwordRepeat: '',
        };
    }

    handleSubmit = (e: MouseEvent) => {
        e.preventDefault();
        this.props.authActions.changePassword(this.state.oldPassword, this.state.password, this.state.passwordRepeat);
    };

    handleChange = (event: ChangeEvent<string>) => {
        const name = event.target.name;
        if(name === "oldPassword"){
            this.setState({ oldPassword : event.target.value});
        }
        else if(name === "password"){
            this.setState({ password : event.target.value});
        }
        else if(name === "passwordRepeat"){
            this.setState({ passwordRepeat : event.target.value});
        }
    };

    render(){
        
        return (
            <form onSubmit={e => e.preventDefault()}>
                <InputField
                    name={'oldPassword'}
                    type={'password'}
                    value={this.state.oldPassword}
                    onChange={this.handleChange}
                    placeholder={'old_password'}
                    inputIcon={'lock'}
                    errors={this.props.errors.changePassword}
                    noLabel
                />

                <InputField
                    name={'password'}
                    type={'password'}
                    value={this.state.password}
                    onChange={this.handleChange}
                    placeholder={'password'}
                    inputIcon={'lock'}
                    errors={this.props.errors.changePassword}
                    noLabel
                />
                <InputField
                    name={'passwordRepeat'}
                    type={'password'}
                    value={this.state.passwordRepeat}
                    onChange={this.handleChange}
                    placeholder={'password_repeat'}
                    inputIcon={'lock'}
                    errors={this.props.errors.changePassword}
                    noLabel
                />
                <Translate id='use_myfloor_for_this' />
                <SubmitButton text={'change_password'} onClick={this.handleSubmit} split disabled />
            </form>
        );
    }
}

export default connect(
    (state: AppState) => ({errors: state.errors.me}),
    dispatch => ({
        authActions: bindActionCreators(actionCreators, dispatch)
    })
)(ChangePasswordForm);