import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {actionCreators as orgActions, OrganizationState} from "../../../store/OrganizationStore";
import Dialog from "../../Dialog";
import {Translate} from "react-localize-redux";
import {InputField, SubmitButton} from "../FormGroup";
import {MeState} from "../../../store/Me";
import {AppState} from "../../../store/configureStore";
import {accountHasAnyPermission, accountIsAdmin} from "../../../model/AccountType";
import ConfirmationForm from "../games/ConfirmationForm";
import {isOrganizationType, Organization} from "../../../model/Organization";
import {DictGetValues} from "../../../services/JsDict";
import OrganizationChilds from "./OrganizationChilds";
import {ErrorState} from "../../../store/Error";

interface Props{
    organization: OrganizationState;
    me: MeState;
    errors: ErrorState['organization'];
    orgActions: typeof orgActions;
}

const OrganizationForm = (props: Props) => {
    if(!props.organization.ui.showEditOrganization) return null;
    if(
        !props.me.account || !accountHasAnyPermission(props.me.account, ["Admin", "ChildOrganizations", "Organization"])
    ) return null;

    const newOrg = props.organization.editOrg;
    const errors = newOrg.new ? props.errors.createNewOrganization : props.errors.updateOrganization;
    const loading = !!(props.organization.loading.createNewOrganization || props.organization.loading.updateOrganization || props.organization.loading.delete);
    
    const newOrgChilds: Organization[] = [];
    newOrg.childOrganizationIds.forEach(x => {
        const o = props.organization.organizations[x];
        if(isOrganizationType(o)){
            newOrgChilds.push(o);
        }
    });

    const me = props.me.account;
    const canSeeOtherThanOwn = me.permissions && (me.permissions.includes("Admin") || me.permissions.includes("ChildOrganizations"));
    const visibleOrganizations = canSeeOtherThanOwn ? DictGetValues(props.organization.organizations) : [];

    
    return(
        <Dialog
            onClose={() => props.orgActions.showEditOrganization(false)}
            loading={loading}
            icon={newOrg.id ? 'trash' : undefined}
            onIconClick={() => props.orgActions.showConfirmDelete(true)}
            className='org-form'
        >
            <h2><Translate id={newOrg.id ? 'organization_title' : 'organization_create_title'}/></h2>
            <form onSubmit={e => e.preventDefault()}>
                {accountIsAdmin(props.me.account) &&
                    <div>
                        <InputField name='systemName' placeholder='systemName' type='text' value={newOrg.systemName} onChange={props.orgActions.setNewValue} noLabel/>
                        <InputField name='type' placeholder='type' type='text' value={newOrg.type} onChange={props.orgActions.setNewValue} noLabel/>
                        <InputField name='country' placeholder='country' type='text' value={newOrg.country} onChange={props.orgActions.setNewValue} noLabel/>
                    </div>
                }
                <InputField name='name' placeholder='name' type='text' value={newOrg.name} onChange={props.orgActions.setNewValue} errors={errors} noLabel/>
                <InputField name='contactName' placeholder='contactName' type='text' value={newOrg.contactName} onChange={props.orgActions.setNewValue} errors={errors} noLabel/>
                <InputField name='contactEmail' placeholder='contactEmail' type='email' value={newOrg.contactEmail} onChange={props.orgActions.setNewValue} errors={errors} noLabel/>
                <InputField name='contactPhone' placeholder='contactPhone' type='text' value={newOrg.contactPhone} onChange={props.orgActions.setNewValue} errors={errors} noLabel/>

                {accountIsAdmin(props.me.account) &&
                    <div>
                        <OrganizationChilds
                            organization={newOrg}
                            visibleOrganizations={visibleOrganizations}
                            orgChildren={newOrgChilds}
                            loading={props.organization.loading.addChildOrg || props.organization.loading.removeChildOrg}
                            orgErrors={props.errors}
                            orgActions={props.orgActions}
                        />
                    </div>
                }
                <Translate id='use_myfloor_for_this' />
                <SubmitButton
                    split
                    text='submit'
                    onClick={newOrg.new ? () => props.orgActions.createNewOrganization(newOrg) : () => props.orgActions.updateOrganization(newOrg)}
                    disabled={true}
                />
                {errors && errors.status === 401 && <div className='form-error'><Translate id='unauthorized_error' /></div>}
            </form>
            {props.organization.ui.showConfirmDelete &&
            <ConfirmationForm
                text={"organization_remove_confirm"}
                confirmText={"yes"}
                cancelText={"no"}
                onConfirm={() => props.orgActions.deleteOrganization(newOrg)}
                onCancel={() => props.orgActions.showConfirmDelete(false)}
            />
            }
        </Dialog>
    )
};


export default connect(
    (state: AppState) => ({
        organization: state.organization,
        me: state.me,
        errors: state.errors.organization
    }),
    dispatch => ({
        orgActions: bindActionCreators(orgActions, dispatch)
    })
)(OrganizationForm);