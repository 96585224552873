import React from 'react';
import {LocalizeContextProps, Translate, withLocalize} from 'react-localize-redux';
import {actionCreators, MeState} from '../../../store/Me';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import Dialog from "../../Dialog";
import ProfileImageForm from "./ProfileImageForm";
import ChangePasswordForm from "./ChangePasswordForm";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {CheckBox, InputField, SelectList, SubmitButton, TextArea} from "../FormGroup";
import {DbOrUploadImage} from "../../../services/ImageHelper";
import profile from '../../../images/missing-profile.png';
import {AppState} from "../../../store/configureStore";
import {ErrorState} from "../../../store/Error";

interface Props extends LocalizeContextProps{
    me: MeState;
    errors: ErrorState['me'];
    meActions: typeof actionCreators;
}

const AccountEditForm = (props: Props) => {
    const showImageForm = props.me.ui.imageForm;
    const showPasswordForm = props.me.ui.changePasswordForm;
    const showEditForm = props.me.ui.editForm;
    if(!showEditForm) return null;
    const account = props.me.editAccount;
    if(!account) return null;

    const updateDto = {
        id: account.id,
        profileImage: account.profileImage,
        firstname: account.firstname || '',
        lastname: account.lastname || '',
        description: account.description || '',
        email: account.email || '',
        language: account.language || 'da',
        isPublic: account.isPublic
    };

    if(showImageForm) return(
        <Dialog onClose={() => props.meActions.setShowImageForm(false)} className='dialog-profile-image' loading={props.me.isLoading.updateImage}>
            <ProfileImageForm />
        </Dialog>
    );
    if(showPasswordForm) return(
        <Dialog onClose={() => props.meActions.setShowChangePasswordForm(false)} className='dialog-profile-password' loading={props.me.isLoading.changePassword}>
            <h2><Translate id='change_password_title'/></h2>
            <ChangePasswordForm />
        </Dialog>
    );

    return (
        <Dialog onClose={() => props.meActions.setShowEditForm(false)} className='dialog-account' loading={!!props.me.isLoading.update}>
            <div className='profile'>
                <span onClick={() => props.meActions.setShowImageForm(true)}>
                    <DbOrUploadImage image={updateDto.profileImage} tempImage={profile}/>
                    <FontAwesomeIcon icon='edit' className='fas-badge'/>
                </span>
            </div>
            <form onSubmit={e => e.preventDefault()}>
                <InputField<string>
                    name='firstname'
                    placeholder='firstname'
                    type='text'
                    value={updateDto.firstname}
                    onChange={props.meActions.setEditField}
                    errors={props.errors.update}
                    noLabel
                />
                <InputField<string>
                    name='lastname'
                    placeholder='lastname'
                    type='text'
                    value={updateDto.lastname}
                    onChange={props.meActions.setEditField}
                    errors={props.errors.update}
                    noLabel
                />
                <InputField<string>
                    name='email'
                    placeholder='email'
                    type='text'
                    value={updateDto.email}
                    onChange={props.meActions.setEditField}
                    errors={props.errors.update}
                    noLabel
                />
                <SelectList
                    name='language'
                    defaultValue={updateDto.language}
                    options={[
                        {name: 'Danish', value:'da'},
                        {name: 'English', value: 'en'},
                        {name: 'Hebrew', value:'iw'},
                        {name: 'Arabic', value:'ar'},
                        {name: 'Dutch', value:'nl'},
                        {name: 'Spanish', value:'es'},
                        {name: 'Norwegian', value: 'no'},
                        {name: 'Swedish', value:'sv'},
                        {name: "French", value: 'fr'},
                        {name: "Lithuanian", value: 'lt'}
                    ]}
                    onChange={(e) => {props.meActions.setEditField(e);}}
                    noLabel
                />
                <CheckBox
                    name={'isPublic'}
                    label={'account_public'}
                    active={updateDto.isPublic}
                    onChange={props.meActions.setEditField}
                />
                <TextArea
                    name='description'
                    placeholder='account_description'
                    value={updateDto.description}
                    onChange={props.meActions.setEditField}
                    errors={props.errors.update}
                    noLabel
                />
                <div className='link-text' onClick={() => props.meActions.setShowChangePasswordForm(true)}><FontAwesomeIcon icon='lock' /> <Translate id='change_password' /></div>
                <Translate id='use_myfloor_for_this' />
                <SubmitButton
                    text='submit'
                    onClick={() => {
                        props.setActiveLanguage(updateDto.language || 'da');
                        props.meActions.update(updateDto);
                    }}
                    split
                    disabled
                />
            </form>
        </Dialog>
    );
};

export default withLocalize(
    connect(
        (state: AppState) => ({me: state.me, errors: state.errors.me}),
        dispatch => ({ meActions: bindActionCreators(actionCreators, dispatch)})
    )(AccountEditForm)
);